import React from 'react'
import PropTypes from 'prop-types'

const Icon = ({name, iconStyle, size, className, ...restProps}) => (
  <i
    className={`fa${iconStyle} fa-${name} ${size ? `fa-${size}` : ''} ${className}`}
    {...restProps}
  />
)

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  iconStyle: PropTypes.string.isRequired,
}

export default Icon
