import React from "react";
import Link from "gatsby-link";
import styled from "@emotion/styled";

import Layout from "../layouts";
import Icon from "../components/Icon";

import { wobble } from "../utils/animations";

const SOCIAL_MEDIA_LINKS = [
  {
    name: "LinkedIn",
    url: "https://www.linkedin.com/in/apiscola/",
    style: "b",
    icon: "linkedin"
  },
  {
    name: "Twitter",
    url: "https://twitter.com/angelpiscola",
    style: "b",
    icon: "twitter"
  },
  // {
  //   name: 'Github',
  //   url: 'https://github.com/redmega',
  //   style: 'b',
  //   icon: 'github-square'
  // },
  // {
  //   name: 'Stack Overflow',
  //   url: 'https://stackoverflow.com/users/2748503/angel-joseph-piscola',
  //   style: 'b',
  //   icon: 'stack-overflow'
  // },
  {
    name: "Gmail",
    url: "mailto:angelpiscola@gmail.com",
    style: "s",
    icon: "at"
  }
];

const SocialMediaLink = styled.a`
  background-image: none;
  margin-right: 2rem;
  display: inline-block;

  :visited {
    color: var(--accent-green);
  }

  :hover {
    animation: ${wobble} 1s ease-in-out;
  }
  :last-of-type {
    margin-right: 0;
  }
  > * {
    margin-top: 1rem;
  }
`;

const PageWrapper = styled.div`
  text-align: left;

  p:not(:last-of-type):before {
    content: "";
    display: inline;
    border: 4px solid var(--background-color-light);
    margin-right: 1rem;
  }

  p:nth-last-of-type(2) {
    margin-bottom: 0;
  }

  .github-icon {
    margin-left: 0.5rem;
  }
`;

const IndexPage = () => {
  return (
    <Layout>
      <PageWrapper>
        <p>Full Stack Developer</p>
        <p>
          {`I like to make things, especially with Javascript `}
          <Icon
            iconStyle="b"
            name="js"
            title="Javascript Icon"
            size="2x"
            style={{ color: "#f7df1e" }}
          />
          {` and React `}
          <Icon
            iconStyle="b"
            name="react"
            title="React Icon"
            size="2x"
            style={{ color: "rgb(97, 218, 251)" }}
          />
        </p>
        <p>
          {`If you want to see some of my OSS work, check out my `}
          <a href="https://github.com/redmega">
            {`Github`}
            <Icon
              className="github-icon"
              iconStyle="b"
              name="github-square"
              title="Github Icon"
              size="2x"
              style={{ color: "black" }}
            />
          </a>
        </p>
        <p>
          {`If you want to see my previous website, you can click `}
          <a
            href="//old.angelpiscola.com"
            target="_blank"
            rel="noreferrer noopener"
          >
            here
          </a>
          .
          {` That site is a product of its times -- written with jQuery and Materialize.css 
        (A jQuery based material design framework), it's covered in parallax images and my attempt at humorous 
        "dictionary entries" attempting to define who and what I was. While some of that is still very much me, 
        I decided that my presence on the web required a makeover. I've put out this very plain website in an 
        attempt to force myself to continue development on this site. After all, the show must go on! `}
          <b>{`If you want to read a more detailed write-up about the motivations behind this site, click `}</b>
          <Link to="/blog/hello-world">here</Link>
          {`.`}
        </p>
        <p>
          <Link to="/blog">
            {`Click here if you came looking for my blog. I have some tech stuff, but mostly poetry.`}
          </Link>
        </p>
        <p>
          {`Right now I'm working for a cool company, but I'm always interested in hearing about new opportunities. 
      Feel free to get in touch any way you know how:`}
        </p>
        <p style={{ marginTop: "1rem" }}>
          {SOCIAL_MEDIA_LINKS.map(({ name, url, icon, style }) => (
            <SocialMediaLink key={name} href={url} target="__blank">
              <Icon iconStyle={style} name={icon} title={name} size="2x" />
            </SocialMediaLink>
          ))}
        </p>
      </PageWrapper>
    </Layout>
  );
};

export default IndexPage;
